*,
::before,
::after{
  box-sizing: border-box;
}


.h1{
  color: white;
}
.root {
  background-color: black;
}
body {
  margin: 0;
  color: white;
  background-color: black;
  font-family: space, space;
}
div{
  font-family: space, space;
}
button{
  color: black;
  font-family: space, space;
}

::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}

input{
  padding: 5px 5px;
  margin-right: 10px;
  height: 25px;
  background-color: rgba(123, 41, 105, 0.6);
  font-family: space;
  border-color: #471D4B;
  border-width: 2px;
  border-radius: 10px;
  border-style: none;
  position: relative;
  transition: width 0.4s ease-in-out;
  color: white;
  font-size: 14px;
  line-height: 18px;
}
label{
  font-size: '20px';
}

input:focus{
  border-color: #471D4B;
  /* color: #7B2969; */
  outline: none;
}

/* .MuiOutlinedInput-notchedOutline {
  border-color: #7B2969 !important;
  color:#7B2969 !important;
}

.MuiFilledInput-root {
  border-bottom: 1px solid #7B2969 !important;
  color:#7B2969!important;
}
.MuiInputBase-input{
  color:#7B2969 !important;
}
.MuiInput-root {
  border-bottom: 1px solid #7B2969 !important;
  color:#7B2969 !important;
}
.MuiButtonBase-root{
  color:#7B2969 !important;
}

.MuiOutlinedInput-input{
  color:#7B2969 !important;
}
.MuiAutocomplete-input{
  color:#7B2969 !important;
}
.MuiAutocomplete-inputFocused{
  color:#7B2969 !important;
} */
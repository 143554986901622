.text-input {
  position: relative;
  padding: 30px;
}

.text-input::after {
  content: '';
  border-radius: 999px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: conic-gradient(
    from var(--from),
    var(--color1),
    var(--color2) var(--distance),
    transparent var(--distance)
  );
  -webkit-mask: radial-gradient(
    farthest-side,
    transparent calc(100% - var(--border)),
    #fff calc(100% - var(--border) + 1px)
  );
  mask: radial-gradient(
    farthest-side,
    transparent calc(100% - var(--border)),
    #fff calc(100% - var(--border) + 1px)
  );
}


.typewriter {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: .15em solid orange; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: .15em; /* Adjust as needed */
  animation: 
    typing 3.5s steps(40, end),
    blink-caret .75s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 70% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: orange; }
}